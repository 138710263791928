import { NavigatorGroup } from '@bbx/search-journey/common/Navigators'
import {
    isTreeAttributeNavigator,
    navigatorContainsAtLeastXPossibleValues,
} from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'

export const getTreeAttributePossibleValues = (navigatorGroups: NavigatorGroup[]) => {
    return navigatorGroups
        .map((group) => group.navigatorList)
        .reduce((a, b) => a.concat(b), [])
        .filter(isTreeAttributeNavigator)
        .filter((navigator) => navigatorContainsAtLeastXPossibleValues(navigator, 5) && navigator.id !== 'Farbe')
}

export const isOnlyCategorySelected = (navigatorGroups: NavigatorGroup[]) => {
    return (
        navigatorGroups
            .map((group) => group.navigatorList)
            .reduce((a, b) => a.concat(b), [])
            .filter((nav) => nav.id !== 'category')
            .filter((navigator) => navigator.selectedValues.length > 0).length === 0
    )
}
