import { CdcAd, RecommendationWidgetType } from '@bbx/common/types/UserFeed'
import React, { FunctionComponent, ReactElement, RefObject } from 'react'
import { SpaceProps } from '@wh-components/system/space'
import { HorizontalSlider, ITEM_HEIGHT_BAP_APPROX } from '@wh/common/chapter/components/HorizontalSlider/HorizontalSlider'
import { findContextLinkWithIdFromArray } from '@bbx/common/types/contextLinks'
import { css } from 'styled-components'
import { CdcAdvertSummaryDiscriminator } from '@bbx/search-journey/common/components/AdvertSummary/CdcAdvertSummaryDiscriminator'
import { Heading } from '@wh-components/core/Heading/Heading'
import { containsOnlyAdsFromVertical } from '@bbx/search-journey/common/components/AdvertSummary/containsOnlyAdsFromVertical'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'
import { buildSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'

interface RecommendationWidgetSliderProps {
    widget?: RecommendationWidgetType
    heading?: ReactElement
    onClickShowAllTagging: () => void
    onClickShowAllButtonTagging: () => void
    onClickArrowTagging: () => void
    onClickAdTagging: (ad: CdcAd, adIndex: number) => void
    visibilityTrackingRef?: RefObject<HTMLDivElement>
    numberOfAdsLimit: number
}

export const RecommendationsWidgetSlider: FunctionComponent<RecommendationWidgetSliderProps & SpaceProps> = ({
    widget,
    onClickShowAllTagging,
    onClickShowAllButtonTagging,
    onClickAdTagging,
    onClickArrowTagging,
    heading,
    visibilityTrackingRef,
    numberOfAdsLimit,
    ...props
}) => {
    if (!widget || widget.ads.length === 0) {
        return null
    }
    const isOnlyBap = containsOnlyAdsFromVertical(verticalIdMap.BAP, widget.ads)
    return (
        <HorizontalSlider
            testIdPrefix="folder-widget"
            heading={heading ?? <Heading level={2} text={widget.title} fontSize="l" />}
            linkText="Alle anzeigen"
            buttonText="Alle anzeigen"
            href={buildSeoUrl(findContextLinkWithIdFromArray('resultListSeoNavigatorLink', widget.contextLinkList)?.relativePath)}
            onClick={onClickShowAllTagging}
            onClickButton={onClickShowAllButtonTagging}
            onArrowBtnClick={onClickArrowTagging}
            css={css`
                ${(p) => p.theme.media.only.tablet} {
                    margin-right: -${(p) => p.theme.space.m}px;
                }
            `}
            visibilityTrackingRef={visibilityTrackingRef}
            numberOfAdsLimit={numberOfAdsLimit}
            {...props}
            buttonHeight={isOnlyBap ? ITEM_HEIGHT_BAP_APPROX : undefined}
            variant={isOnlyBap ? 'portrait' : 'landscape'}
        >
            {widget.ads.slice(0, numberOfAdsLimit).map((ad, index) => {
                return (
                    <CdcAdvertSummaryDiscriminator
                        ad={ad}
                        key={ad.id}
                        testIdPrefix="recommendation"
                        onClickTaggingEvent={() => onClickAdTagging(ad, index)}
                        variant={isOnlyBap ? 'portrait' : 'landscape'}
                    />
                )
            })}
        </HorizontalSlider>
    )
}
