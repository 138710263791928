import React, { FunctionComponent } from 'react'
import { NavigatorId } from '@bbx/search-journey/common/Navigators'
import { Box } from '@wh-components/core/Box/Box'
import { Button } from '@wh-components/core/Button/Button'

export const MultiSelectModalFooter: FunctionComponent<{
    id: NavigatorId
    close: () => void
    submit: (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => Promise<void>
}> = (props) => {
    return (
        <Box display="flex" justifyContent="space-between">
            <Button
                flex={{ phone: '1 1 50%', tablet: 'inherit' }}
                minWidth={{ tablet: 150 }}
                marginRight="m"
                color="complimentary"
                testId={`${props.id}-dismiss-modal`}
                onClick={props.close}
                size={{ phone: 'large', tablet: 'medium' }}
            >
                Abbrechen
            </Button>
            <Button
                flex={{ phone: '1 1 50%', tablet: 'inherit' }}
                minWidth={{ tablet: 150 }}
                testId={`${props.id}-submit-modal`}
                onClick={props.submit}
                size={{ phone: 'large', tablet: 'medium' }}
            >
                Fertig
            </Button>
        </Box>
    )
}
