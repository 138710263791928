import { PlaceholderImage } from '@wh/common/chapter/components/PlaceholderImage'
import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { inverseAdTypeIdMap } from '@wh/common/chapter/types/AdType'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { PayliveryBadge } from '@wh/common/chapter/components/PayliveryBadge/PayliveryBadge'
import { getBapAdvertPrice } from '@bbx/search-journey/common/lib/getBapAdvertPrice'
import { getSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import { hasPaylivery } from '@bbx/common/lib/isPayliveryAd'
import { ResultListSaveAdButton } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListSaveAdButton'
import { AdRowAddressLine } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/AdRow/AdRowAddressLine'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { formatPrice } from '@wh/common/chapter/lib/formatter'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { inverseVerticalIdMap } from '@wh/common/chapter/types/verticals'
import React, { FunctionComponent, ReactElement } from 'react'
import { css } from 'styled-components'
import { AdRowTimeDelta } from '@bbx/search-journey/common/components/AdvertSummary/AdRowTimeDelta'
import { adFocusHoverStyles } from '@wh/common/chapter/components/adFocusHoverStyles'
import { AdBadges } from '@bbx/search-journey/common/components/AdvertSummary/AdBadges'
import { BumpedTooltip } from '@bbx/search-journey/common/components/BumpedTooltip/BumpedTooltip'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { AspectRatioBox } from '@wh-components/core/AspectRatioBox/AspectRatioBox'

interface BapAdRowProps {
    advertSummary: AdvertSummary
    taggingData: TaggingData
    className?: string
    imageOverlay?: ReactElement
    teaserAttributes: string | undefined
    onClickTaggingAction?: () => Promise<void> | void
    payliveryBadgeText: string
}

export const BapAdRow: FunctionComponent<BapAdRowProps> = ({
    advertSummary,
    taggingData,
    className,
    onClickTaggingAction,
    imageOverlay,
    teaserAttributes,
    payliveryBadgeText,
}) => {
    const { mainPrice } = getBapAdvertPrice(advertSummary.adTypeId, advertSummary.advertStatus, advertSummary.attributes)
    const highlighted = Boolean(getAttributeValue(advertSummary.attributes.attribute, 'RESULT_LIST_STYLE2'))
    const link = getSeoUrl(advertSummary)
    const image = advertSummary.advertImageList.advertImage[0]
    const oldPrice = getAttributeValue(advertSummary.attributes.attribute, 'OLD_PRICE_FOR_DISPLAY')
    const isBumped = getAttributeValue(advertSummary.attributes.attribute, 'IS_BUMPED') === '1'

    return (
        <Box position="relative">
            <Box
                id={advertSummary.id}
                padding="m"
                borderBottom="owl"
                className={className}
                position="relative"
                css={adFocusHoverStyles(highlighted)}
            >
                <ClientRoutingAnchorLink
                    type="anchor"
                    href={link}
                    color="palette.verydarkgrey"
                    underline="none"
                    onClick={onClickTaggingAction}
                    id={`search-result-entry-header-${advertSummary.id}`}
                    testId={`search-result-entry-header-${advertSummary.id}`}
                    aria-labelledby={`search-result-entry-header-${advertSummary.id}`}
                    display="flex"
                    justifyContent="flex-start"
                    css={css`
                        outline: 0;
                    `}
                >
                    <Box marginRight={{ phone: 's', tablet: 'm' }} position="relative">
                        <AspectRatioBox
                            width={{ phone: 140, tablet: 200 }}
                            height={{ phone: 140, tablet: 'auto' }}
                            ratio={4 / 3}
                            backgroundColor="palette.babyseal"
                        >
                            {image ? (
                                <ResponsiveImage
                                    src={image.mainImageUrl}
                                    alt={image?.description ?? ''}
                                    objectFit={{ phone: 'cover', tablet: 'contain' }}
                                />
                            ) : (
                                <Box>
                                    <PlaceholderImage
                                        vertical={inverseVerticalIdMap[advertSummary.verticalId]}
                                        adType={inverseAdTypeIdMap[advertSummary.adTypeId]}
                                    />
                                </Box>
                            )}
                        </AspectRatioBox>
                        {imageOverlay}
                        <AdBadges
                            advertStatus={advertSummary.advertStatus}
                            attributes={advertSummary.attributes.attribute}
                            position="absolute"
                            top="xs"
                            left="xs"
                        />
                    </Box>

                    <Box display="flex" flexDirection="column" minWidth="10px" flex={1}>
                        <Text as="h3" fontSize="l" fontWeight="normal" lineClamp={2} color="palette.verydarkgrey" minWidth={0}>
                            {isBumped && <BumpedTooltip />}
                            {advertSummary.description}
                        </Text>
                        <Box order={3} display="flex" flexDirection="column" flex={1}>
                            <Box
                                display="flex"
                                width="100%"
                                height={{ phone: '100%', tablet: 'auto' }}
                                flexDirection={{ phone: 'column', tablet: 'row' }}
                                justifyContent={{ phone: 'space-between', tablet: 'flex-start' }}
                                alignItems="baseline"
                            >
                                <Box flex={{ tablet: '1' }} display="flex" alignItems="center" flexWrap="wrap" columnGap="s">
                                    <Text
                                        fontSize="xl"
                                        fontWeight="bold"
                                        color="palette.primary.main"
                                        testId={`search-result-entry-price-${advertSummary.id}`}
                                        aria-label={mainPrice.value && formatPrice(mainPrice.value)}
                                    >
                                        {mainPrice.value && formatPrice(mainPrice.value)}
                                    </Text>
                                    {oldPrice && (
                                        <Text
                                            fontSize="m"
                                            color="palette.elephant"
                                            css={css`
                                                text-decoration: line-through;
                                            `}
                                        >
                                            {oldPrice}
                                        </Text>
                                    )}

                                    {hasPaylivery(advertSummary.attributes.attribute) && (
                                        <PayliveryBadge id={`ad-paylivery-badge-${advertSummary.id}`} text={payliveryBadgeText} />
                                    )}
                                </Box>
                                {teaserAttributes && (
                                    <Box flex={{ phone: 0, tablet: 1 }}>
                                        <Text
                                            color="palette.elephant"
                                            fontSize="m"
                                            testId={`search-result-entry-teaser-attributes-${advertSummary.id}`}
                                            lineClamp={1}
                                        >
                                            {teaserAttributes}
                                        </Text>
                                    </Box>
                                )}
                            </Box>
                            <Box display={{ phone: 'none', desktop: 'inline' }} marginTop="xs">
                                <Text fontSize="s" color="palette.raccoon" lineHeight="1.3" lineClamp={2} aria-hidden={true}>
                                    {getAttributeValue(advertSummary.attributes.attribute, 'BODY_DYN')}
                                </Text>
                            </Box>
                            <Box display="flex" flexDirection="column" marginTop={{ phone: 0, tablet: 'auto' }}>
                                <AdRowTimeDelta advertSummary={advertSummary} />

                                <AdRowAddressLine id={advertSummary.id} attributes={advertSummary.attributes} />
                            </Box>
                        </Box>
                    </Box>
                    {/* spacing to truncate header text */}
                    <Box marginLeft="46px" />
                </ClientRoutingAnchorLink>
            </Box>

            <ResultListSaveAdButton
                position="absolute"
                top="m"
                right="m"
                adId={advertSummary.id}
                adTitle={advertSummary.description}
                taggingData={taggingData}
                testId={`search-result-entry-save-ad-${advertSummary.id}`}
                zIndex="popover"
            />
        </Box>
    )
}
