import { AdvertSummaryComponent } from '@bbx/search-journey/common/components/AdvertSummary/AdvertSummary'
import { getSeoUrlCdc } from '@bbx/search-journey/common/lib/getSeoUrl'
import { verticals } from '@wh/common/chapter/types/verticals'
import React, { FunctionComponent } from 'react'
import { CdcAdvertSummaryProps } from '@bbx/search-journey/common/components/AdvertSummary/CdcAdvertSummaryProps'

export const CdcBapAdvertSummaryComponent: FunctionComponent<CdcAdvertSummaryProps> = ({
    ad,
    testIdPrefix,
    isActive = true,
    onClickTaggingEvent,
    color,
    accentColor,
    variant = 'portrait',
}) => {
    return (
        <AdvertSummaryComponent
            attributes={ad.attributes}
            teaserAttributes={ad.teaserAttributes}
            key={ad.id}
            adTypeId={ad.adTypeId}
            description={ad.description}
            advertImage={ad.advertImageList?.[0]?.mainImageUrl}
            advertStatus={ad.advertStatus}
            href={getSeoUrlCdc(ad)}
            vertical={verticals.BAP}
            adId={String(ad.id)}
            testIdPrefix={testIdPrefix}
            isActive={isActive}
            onClickTaggingEvent={onClickTaggingEvent}
            color={color}
            inactiveColor={color}
            accentColor={accentColor}
            variant={variant}
        />
    )
}
