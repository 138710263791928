import { Navigator, RangeNavigator } from '@bbx/search-journey/common/Navigators'
import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import React, { FunctionComponent } from 'react'
import { BubbleMultiSelectNavigator } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/BubbleMultiSelectNavigator'
import { ProvinceBubbleNavigator } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/ProvinceBubbleNavigator'
import { isMultiSelectNavigator, isRangeNavigator } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { BubblePriceNavigator } from './BubblePriceNavigator'
import { getCheckboxContent } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/lib/getCheckboxContent'

import { BubbleSearchAttributesMultiSelect } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/BubbleSearchAttributesMultiSelect'
import { BubbleSearchMultiSelectNavigator } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/BubbleSearchMultiSelectNavigator'

export const BubbleNavigatorDiscriminator: FunctionComponent<NavigatorProps & { districtNavigator: Navigator | RangeNavigator }> = ({
    navigator,
    districtNavigator,
    onSearch,
    taggingData,
}) => {
    if (navigator.navigatorValuesDisplayType === 'IMAGE') {
        return (
            <BubbleMultiSelectNavigator
                navigator={navigator}
                onSearch={onSearch}
                taggingData={taggingData}
                checkedContent={getCheckboxContent('svg-selected')}
                uncheckedContent={getCheckboxContent('svg-normal')}
                checkboxSize="touchscreen"
            />
        )
    } else if (navigator.id === 'state') {
        return (
            <ProvinceBubbleNavigator
                navigator={navigator}
                districtNavigator={districtNavigator}
                onSearch={onSearch}
                aria-labelledby=""
                taggingData={taggingData}
            />
        )
    } else if (navigator.id === 'district') {
        return null
    } else if (navigator.id === 'Marke') {
        if (navigator.metadata?.attributeSearchLink) {
            return <BubbleSearchAttributesMultiSelect navigator={navigator} onSearch={onSearch} taggingData={taggingData} />
        } else {
            return <BubbleSearchMultiSelectNavigator navigator={navigator} onSearch={onSearch} taggingData={taggingData} />
        }
    } else if (isMultiSelectNavigator(navigator)) {
        return <BubbleMultiSelectNavigator navigator={navigator} onSearch={onSearch} taggingData={taggingData} />
    } else if (navigator.id === 'price' && isRangeNavigator(navigator)) {
        return (
            <BubblePriceNavigator
                navigator={navigator}
                onSearch={onSearch}
                taggingData={taggingData}
                marginBottom="s"
                abortRequest={() => {}}
            />
        )
    }

    return null
}
