import React, { FunctionComponent } from 'react'
import { AttributesList, getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'

interface AdRowAddressLineProps {
    id: string
    attributes: AttributesList
}

export const AdRowAddressLine: FunctionComponent<AdRowAddressLineProps> = ({ id, attributes }) => {
    const postCode = getAttributeValue(attributes.attribute, 'POSTCODE')
    const postalName = getAttributeValue(attributes.attribute, 'LOCATION')

    const address = [postCode, postalName].filter(Boolean).join(' ')

    return (
        <Box display="flex" testId={`search-result-entry-subheader-${id}`}>
            <Text fontSize="s" color="palette.raccoon" truncate={true} aria-label={`Wird verkauft in ${address}`}>
                {address}
            </Text>
        </Box>
    )
}
