import React, { FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { BrightSkeletonLine } from '@wh/common/chapter/components/Skeletons/Skeletons'
import { AspectRatioBox } from '@wh-components/core/AspectRatioBox/AspectRatioBox'

export const ResultListGridSkeleton: FunctionComponent = () => (
    <Box padding="s" borderBottom="owl" className="grid-view-ad-cell">
        <Box display="flex" flexDirection="column" gap="sm">
            <Box width="100%" marginRight={{ phone: 's', tablet: 'm' }}>
                <AspectRatioBox width="100%" ratio={4 / 5}>
                    <Box height="100%">
                        <BrightSkeletonLine />
                    </Box>
                </AspectRatioBox>
            </Box>
            <Box display="flex" flexDirection="column" flex={1}>
                <Box display="flex" gap="xs" flexDirection="column">
                    <Box height={18} width="100%">
                        <BrightSkeletonLine />
                    </Box>
                    <Box height={18} width="60%">
                        <BrightSkeletonLine />
                    </Box>
                    <Box height={18} width="30%">
                        <BrightSkeletonLine />
                    </Box>
                </Box>
            </Box>
        </Box>
    </Box>
)
