/* eslint-disable max-lines */
import { useResultListDigitalAdvertising } from '@bbx/common/digital-advertising/hooks/useResultListDigitalAdvertising'
import { NextDynamicRoute } from '@bbx/common/routing/nextDynamicRoutes'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { Breadcrumbs } from '@bbx/search-journey/common/components/Breadcrumbs/Breadcrumbs'
import { ItemListLd } from '@bbx/search-journey/common/components/JsonLd/ItemListLd'
import { SaveAdModal } from '@bbx/search-journey/common/components/SaveAd/SaveAdModal'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { SeoSearchTerms } from '@bbx/search-journey/common/SeoSearchTerms'
import { SearchAgentUIState } from '@bbx/search-journey/sub-domains/search/components/common/common/CreateSearchAgentButton/CreateSearchAgentButton'
import { CreateSearchAgentModal } from '@bbx/search-journey/sub-domains/search/components/common/common/CreateSearchAgentButton/CreateSearchAgentModal'
import { StickyCreateSearchAgentButton } from '@bbx/search-journey/sub-domains/search/components/common/common/CreateSearchAgentButton/StickyCreateSearchAgentButton'
import { MemoizedResultListAdRows } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListAdRows'
import { ResultListSaveAdButtonProvider } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListSavedAdButtonContext'
import { EmptyResultList } from '@bbx/search-journey/sub-domains/search/components/common/result-list/EmptyResultList/EmptyResultList'
import { FilterSideBar } from '@bbx/search-journey/sub-domains/search/components/common/result-list/FilterSidebar/FilterSideBar'
import { ResultListBottomCreateSearchAgentButton } from '@bbx/search-journey/sub-domains/search/components/common/result-list/ResultListBottomCreateSearchAgentButton/ResultListBottomCreateSearchAgentButton'
import { ResultListBottomNavigation } from '@bbx/search-journey/sub-domains/search/components/common/result-list/ResultListBottomNavigation/ResultListBottomNavigation'
import { ResultListHeading } from '@bbx/search-journey/sub-domains/search/components/common/result-list/ResultListHeading/ResultListHeading'
import { useBackButtonSearch } from '@bbx/search-journey/sub-domains/search/components/common/result-list/hooks/useBackButtonSearch'
import { useResultListSearch } from '@bbx/search-journey/sub-domains/search/components/common/result-list/hooks/useResultListSearch'
import { MemoizedBapNavigators } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/BapNavigators'
import { Box } from '@wh-components/core/Box/Box'
import { DmpStateProvider } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { DynamicRendering } from '@wh/common/chapter/components/DynamicRendering/DynamicRendering'
import { isUserLoggedIn, useProfileData } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { SeoMetaTagsForSeoMetaData } from '@wh/common/chapter/components/SeoMetaTags/SeoMetaTags'
import { SkipToContent } from '@wh/common/chapter/components/SkipToContent/SkipToContent'
import { useScreenSize } from '@wh/common/chapter/components/UserAgentProvider/useUserAgent'
import { formatNumber } from '@wh/common/chapter/lib/formatter'
import { callActionEvent, callPageView } from '@wh/common/chapter/lib/tagging/tagging'
import React, { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { css } from 'styled-components'
import { ResultListHeaderSticky } from '../../../common/result-list/ResultListHeaderSticky/ResultListHeaderSticky'
import { TopAdsLarge } from '../../../common/result-list/TopAds/TopAdsLarge'
import { TopAdsSmall } from '../../../common/result-list/TopAds/TopAdsSmall'
import { topAdBapConfigMap } from './lib/topAdBapConfig'
import {
    CategorySuggestionBubbles,
    showCategorySuggestionBubbles,
} from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/CategorySuggestionBubbles'
import { BapAutocompleteCategory } from '@bbx/search-journey/common/BapAutocompleteResponse'
import { SearchTermsLinkVomit } from '@bbx/search-journey/common/components/SearchTermsLinkVomit/SearchTermsLinkVomit'
import { BapResultListActionBar } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/BapResultListActionBar'
import { getNumberOfSelectedTreeAttributes } from '@bbx/search-journey/sub-domains/search/lib/getNumberOfSelectedTreeAttributes'
import { PossibleValueLinkVomit } from '@bbx/search-journey/sub-domains/search/components/common/common/PossibleValueLinkVomit/PossibleValueLinkVomit'
import {
    getTreeAttributePossibleValues,
    isOnlyCategorySelected,
} from '@bbx/search-journey/sub-domains/search/lib/getTreeAttributePossibleValues'
import { ViewModeButtons } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/ViewModeButtons/ViewModeButtons'
import { ListOrGridViewAdRow } from './AdRow/ListOrGridViewAdRow'
import { useFromExpiredAd } from '@bbx/search-journey/sub-domains/search/components/common/result-list/hooks/useFromExpiredAd'
import { useResultListSavedAds } from '@bbx/search-journey/sub-domains/search/components/common/result-list/hooks/useResultListSavedAds'
import { useNavigatorSidebar } from '@bbx/search-journey/sub-domains/search/components/common/result-list/hooks/useNavigatorSidebar'
import { getCurrentAndTotalPages } from '@bbx/search-journey/sub-domains/search/components/common/result-list/getCurrentAndTotalPages'
import { TaggingPage } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { useLoadJobsWidget } from '@bbx/search-journey/common/lib/useLoadJobsWidget'
import { ResultListJobsWidget } from '@bbx/search-journey/sub-domains/search/components/common/result-list/JobsWidgetWrapper/ResultListJobsWidget'
import { ResultListRecommendationWidgetWrapper } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/RecommendationWidgetWrapper/ResultListRecommendationWidget'
import { DesktopBreadcrumbs } from '@bbx/search-journey/sub-domains/search/components/common/result-list/DesktopBreadcrumbs/DesktopBreadcrumbs'
import { getUniversalBbxCookie } from '@wh/common/chapter/types/cookies'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'
import { getFlatPossibleValues } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { SortOrderInfo } from '@bbx/search-journey/sub-domains/search/components/common/result-list/SortOrderInfo/SortOrderInfo'
import { FashionUserZoom } from '@bbx/common/components/UserZoom/UserZoom'
import { FASHION_CATEGORIES, isBapSearchInCategory } from '@bbx/search-journey/sub-domains/search/lib/isSearchInCategory'
import { ViewMode } from '@bbx/search-journey/common/lib/viewModeHelper'
import { SaveSizeFakeDoor } from './SaveSizeFakeDoor/SaveSizeFakeDoor'

export interface ResultListContainerProps {
    searchResult: SearchResult
    searchTerms?: SeoSearchTerms[]
    fromExpiredAdId?: string | undefined
    initialCategorySuggestions?: BapAutocompleteCategory[] | undefined
    viewMode: ViewMode
    setViewMode?: (viewMode: ViewMode, options: { setCookie: boolean }) => void
    pageViewEvent?: TaggingPage
    pageType?: 'seller-profile' | 'dealer-profile' | 'image-search'
}

export const BapResultListContainer: FunctionComponent<ResultListContainerProps> = ({
    searchResult,
    searchTerms = [],
    fromExpiredAdId,
    initialCategorySuggestions,
    viewMode,
    setViewMode,
    pageViewEvent = 'search_result_list',
    pageType,
}) => {
    const [profileData] = useProfileData()
    const loginId = isUserLoggedIn(profileData) ? profileData.loginId : undefined
    const { savedAdsUiState, setSavedAdsUiState, savedAdsIdList, updateSavedAdsIdList } = useResultListSavedAds(loginId)
    const showTopAds = !!findContextLinkWithId('fetchTopAds', searchResult.searchContextLinks)
    const showBreadcrumbs = pageType !== 'seller-profile' && pageType !== 'dealer-profile'
    const hideJobRecommendations = pageType === 'seller-profile' || pageType === 'dealer-profile'
    const showCategorySuggestions = pageType !== 'seller-profile' && pageType !== 'dealer-profile'
    const isImageSearch = pageType === 'image-search'

    const [searchAgentUIState, setSearchAgentUIState] = useState<SearchAgentUIState>({ state: 'idle' })
    const onTriggeredSearchSucceeded = useCallback(() => {
        setSearchAgentUIState({ state: 'idle' })
        setSavedAdsUiState({ state: 'idle' })
    }, [setSearchAgentUIState, setSavedAdsUiState])

    const {
        currentSearchResult,
        topAds,
        triggerSearch,
        canResetSearch,
        resetSearch,
        isLoading,
        isLoadingTopAds,
        abortRequest,
        suggestedCategories,
    } = useResultListSearch(
        searchResult,
        onTriggeredSearchSucceeded,
        NextDynamicRoute.BAP_DETAIL_PAGE,
        initialCategorySuggestions,
        setViewMode,
    )

    const regularNonStickyHeaderVisibilityTrackingRef = useRef<HTMLDivElement>(null)
    const resultListVisibilityTrackingRef = useRef<HTMLDivElement>(null)
    const scrollAbleFilterSideBarRef = useRef<HTMLDivElement>(null)
    const paginationRef = useRef<HTMLDivElement>(null)
    const screenSize = useScreenSize()
    const searchAgentCreateLink = findContextLinkWithId('searchAgentCreateLink', currentSearchResult.searchContextLinks)
    const searchAgentOptionsLink = findContextLinkWithId('searchAgentOptionsLink', currentSearchResult.searchContextLinks)

    const treeNavigatorsForLinkVomit = getTreeAttributePossibleValues(currentSearchResult.navigatorGroups)
    const showAttributeLinkVomit = isOnlyCategorySelected(currentSearchResult.navigatorGroups) && !isImageSearch

    const { currentPage, totalPages } = getCurrentAndTotalPages(
        currentSearchResult,
        currentSearchResult.advertSummaryList?.advertSummary.length > 0,
    )

    useBackButtonSearch(triggerSearch, isImageSearch)

    useEffect(() => {
        callPageView(pageViewEvent, {
            taggingData: currentSearchResult.taggingData,
            pageParameters: { xiti_x4: viewMode === 'list' ? '12' : '13' },
        })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [currentSearchResult])

    const { showNavigatorSidebar, setShowNavigatorSidebar } = useNavigatorSidebar(screenSize)

    const { trackCustomDmpEvent } = useResultListDigitalAdvertising(
        currentSearchResult.advertisingParametersV2,
        currentSearchResult.dmpParameters,
        currentSearchResult.dmpUserIdentities,
        currentSearchResult.pageRequested,
        currentSearchResult.rowsReturned,
        showNavigatorSidebar,
        viewMode,
    )

    useFromExpiredAd(fromExpiredAdId)

    const topAdsSmall = useMemo(
        () => (
            <Fragment>
                {showTopAds && (
                    <TopAdsSmall
                        key="tops-ads"
                        topAds={topAds}
                        topAdConfigMap={topAdBapConfigMap}
                        isLoading={isLoadingTopAds}
                        taggingData={currentSearchResult.taggingData}
                        borderBottom="owl"
                        verticalId={verticalIdMap.BAP}
                    />
                )}
            </Fragment>
        ),
        [topAds, isLoadingTopAds, showTopAds, currentSearchResult],
    )
    const isJobsWidgetHidden = (getUniversalBbxCookie('bbxHideJobsResultListWidget', undefined) ?? 'false') === 'true'

    const jobsWidget = useLoadJobsWidget(paginationRef, isJobsWidgetHidden || hideJobRecommendations)
    return (
        <DmpStateProvider trackCustomDmpEvent={trackCustomDmpEvent}>
            {isBapSearchInCategory(currentSearchResult, FASHION_CATEGORIES) && <FashionUserZoom />}
            <Box>
                <ItemListLd searchResult={currentSearchResult} />
                <ResultListHeaderSticky
                    searchResult={currentSearchResult}
                    setSearchAgentUIState={setSearchAgentUIState}
                    setShowNavigatorSidebar={setShowNavigatorSidebar}
                    regularNonStickyHeaderVisibilityTrackingRef={regularNonStickyHeaderVisibilityTrackingRef}
                    resultListVisibilityTrackingRef={resultListVisibilityTrackingRef}
                    onSearch={triggerSearch}
                />
                {showBreadcrumbs && <DesktopBreadcrumbs breadcrumbs={currentSearchResult.breadcrumbs} />}
                {showTopAds && (
                    <TopAdsLarge
                        topAds={topAds}
                        topAdConfigMap={topAdBapConfigMap}
                        isLoading={isLoadingTopAds}
                        taggingData={currentSearchResult.taggingData}
                        marginBottom="m"
                        verticalId={verticalIdMap.BAP}
                    />
                )}
                <CreateSearchAgentModal
                    searchAgentCreateLink={searchAgentCreateLink}
                    searchAgentUIState={searchAgentUIState}
                    setSearchAgentUIState={setSearchAgentUIState}
                    searchAgentOptionsLink={searchAgentOptionsLink}
                />
                <SaveAdModal
                    savedAdsUiState={savedAdsUiState}
                    setSavedAdsUiState={setSavedAdsUiState}
                    loginId={loginId}
                    onSavedTaggingEvent={(adId) =>
                        callActionEvent('search_result_list_ad_saved', currentSearchResult.taggingData, { favorite_ad: adId })
                    }
                />
                <SeoMetaTagsForSeoMetaData
                    seoMetaData={searchResult.seoMetaData}
                    noindex={searchResult.rowsFound === 0 || getNumberOfSelectedTreeAttributes(currentSearchResult.navigatorGroups) >= 2}
                    nofollow={searchResult.rowsFound === 0}
                />
                <Box position="relative">
                    <SkipToContent targetId="skip-to-resultlist" text="Zu den Suchergebnissen" />
                    <ResultListHeading
                        heading={`${formatNumber(currentSearchResult.rowsFound)} ${currentSearchResult.heading}`}
                        changeViewButtons={
                            setViewMode ? (
                                <ViewModeButtons
                                    viewMode={viewMode}
                                    activateListView={() => {
                                        setViewMode('list', { setCookie: true })
                                        callActionEvent('search_result_list_list_view_click', currentSearchResult.taggingData)
                                    }}
                                    activateGridView={() => {
                                        setViewMode('grid', { setCookie: true })
                                        callActionEvent('search_result_list_grid_view_click', currentSearchResult.taggingData)
                                    }}
                                />
                            ) : undefined
                        }
                    />
                    <Box display="flex">
                        <FilterSideBar
                            showFilterSideBar={showNavigatorSidebar}
                            setShowFilterSideBar={setShowNavigatorSidebar}
                            canResetSearch={canResetSearch}
                            resetSearch={resetSearch}
                            currentSearchResult={currentSearchResult}
                            searchAgentCreateLink={searchAgentCreateLink}
                            searchAgentOptionsLink={searchAgentOptionsLink}
                            setSearchAgentUIState={setSearchAgentUIState}
                            scrollAbleFilterSideBarRef={scrollAbleFilterSideBarRef}
                            navigators={
                                <MemoizedBapNavigators
                                    searchResult={currentSearchResult}
                                    onSearch={triggerSearch}
                                    abortRequest={abortRequest}
                                    searchId={currentSearchResult.searchId}
                                    taggingData={currentSearchResult.taggingData}
                                    // setting the scrollRootRef on desktop would set the rootMargin on tablet/desktop and break dynamic rendering as it would intersect immediately because there is no overflow:scroll container
                                    scrollRootRef={screenSize === 'phone' ? scrollAbleFilterSideBarRef : undefined}
                                />
                            }
                        />
                        <Box minWidth="0" flex="1 1 100%" display="flex" flexDirection="column">
                            <BapResultListActionBar
                                currentSearchResult={currentSearchResult}
                                triggerSearch={triggerSearch}
                                regularNonStickyHeaderVisibilityTrackingRef={regularNonStickyHeaderVisibilityTrackingRef}
                                totalPages={totalPages}
                                currentPage={currentPage}
                                setShowNavigatorSidebar={setShowNavigatorSidebar}
                                taggingData={currentSearchResult.taggingData}
                                isImageSearch={isImageSearch}
                            />
                            {showCategorySuggestions && (
                                <CategorySuggestionBubbles
                                    suggestedCategories={suggestedCategories}
                                    searchResult={currentSearchResult}
                                    triggerSearch={triggerSearch}
                                />
                            )}
                            <SaveSizeFakeDoor slot="srlDesktop" searchResult={currentSearchResult} marginTop="m" />
                            <Box
                                id="skip-to-resultlist"
                                ref={resultListVisibilityTrackingRef}
                                display="flex"
                                flex="1 1 auto"
                                alignContent="flex-start"
                                flexWrap="wrap"
                                paddingVertical={viewMode === 'grid' ? 's' : undefined}
                                css={css`
                                    & > .grid-view-ad-cell {
                                        width: 50%;
                                        ${(p) => p.theme.media.tablet} {
                                            width: 33.3333%;
                                        }
                                    }
                                    & > :not(.grid-view-ad-cell) {
                                        width: 100%;
                                    }
                                `}
                            >
                                {!showCategorySuggestionBubbles(currentSearchResult, suggestedCategories) && (
                                    <SortOrderInfo marginTop="s" marginBottom={{ phone: 's', tablet: 'm' }} />
                                )}
                                <ResultListSaveAdButtonProvider savedAdsIdList={savedAdsIdList} updateSavedAdsIdList={updateSavedAdsIdList}>
                                    {currentSearchResult.rowsFound ? (
                                        <MemoizedResultListAdRows
                                            advertSummaries={currentSearchResult.advertSummaryList.advertSummary}
                                            page={currentPage}
                                            newAdsSeparatorPosition={currentSearchResult.newAdsSeparatorPosition}
                                            TopAdsComponent={topAdsSmall}
                                            viewMode={viewMode}
                                        >
                                            {currentSearchResult.advertSummaryList.advertSummary.map((advertSummary, key) => (
                                                <ListOrGridViewAdRow
                                                    advertSummary={advertSummary}
                                                    index={key}
                                                    key={key}
                                                    isLoading={isLoading}
                                                    currentSearchResult={currentSearchResult}
                                                    viewMode={viewMode}
                                                />
                                            ))}
                                        </MemoizedResultListAdRows>
                                    ) : (
                                        <EmptyResultList
                                            searchResult={currentSearchResult}
                                            onSearch={triggerSearch}
                                            setSearchAgentUIState={setSearchAgentUIState}
                                        />
                                    )}
                                </ResultListSaveAdButtonProvider>
                            </Box>
                            {jobsWidget && jobsWidget.ads?.length > 0 && (
                                <ResultListJobsWidget
                                    jobsWidget={jobsWidget}
                                    taggingData={currentSearchResult.taggingData}
                                    showBottomDivider={currentSearchResult.rowsFound > 0}
                                />
                            )}
                            <ResultListBottomNavigation
                                searchResult={currentSearchResult}
                                currentPage={currentPage}
                                triggerSearch={triggerSearch}
                                totalPages={totalPages}
                                showAdsPerPage={!isImageSearch}
                                paginationRef={paginationRef}
                            />

                            {searchAgentCreateLink && searchAgentOptionsLink && (
                                <ResultListBottomCreateSearchAgentButton
                                    currentSearchResult={currentSearchResult}
                                    searchAgentCreateLink={searchAgentCreateLink}
                                    searchAgentOptionsLink={searchAgentOptionsLink}
                                    setSearchAgentUIState={setSearchAgentUIState}
                                />
                            )}
                        </Box>
                    </Box>
                    {((jobsWidget && jobsWidget.ads?.length === 0) || isJobsWidgetHidden) && ( // only render this when no jobs ads are
                        // returned
                        <ResultListRecommendationWidgetWrapper
                            visibilityRef={paginationRef}
                            taggingData={currentSearchResult.taggingData}
                        />
                    )}
                    {searchAgentCreateLink && !showNavigatorSidebar && (
                        <StickyCreateSearchAgentButton
                            marginTop="s"
                            searchAgentCreateLink={searchAgentCreateLink}
                            searchAgentOptionsLink={searchAgentOptionsLink}
                            setSearchAgentUIState={setSearchAgentUIState}
                            testId="create-search-agent-button-sticky-small"
                            clickActionEvent="search_result_list_search_agent_click_sticky_small"
                            taggingData={currentSearchResult.taggingData}
                        />
                    )}
                    {currentSearchResult.breadcrumbs && showBreadcrumbs && (
                        <DynamicRendering<HTMLDivElement> renderPlaceholder={(ref) => <Box ref={ref} />}>
                            <Box display={{ phone: 'block', tablet: 'none' }} padding="m">
                                <Breadcrumbs breadcrumbsList={currentSearchResult.breadcrumbs} />
                            </Box>
                        </DynamicRendering>
                    )}
                    {showAttributeLinkVomit &&
                        treeNavigatorsForLinkVomit?.map((navigator) => (
                            <Box
                                key={`link-vomit-${navigator.id}`}
                                paddingVertical={{ tablet: 'm' }}
                                borderTop={{ tablet: 'owl' }}
                                css={css`
                                    &:empty {
                                        display: none;
                                    }
                                `}
                            >
                                <PossibleValueLinkVomit title={navigator.label} possibleValues={getFlatPossibleValues(navigator)} />
                            </Box>
                        ))}
                    {searchTerms && (
                        <Box paddingVertical={{ tablet: 'm' }} borderTop={{ tablet: 'owl' }}>
                            <SearchTermsLinkVomit searchTermsList={searchTerms} />
                        </Box>
                    )}
                </Box>
            </Box>
        </DmpStateProvider>
    )
}
