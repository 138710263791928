import React, { FunctionComponent } from 'react'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { BapAdRowWrapper } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/AdRow/BapAdRowWrapper'
import { GridViewAdRowWrapper } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/GridViewAdRowWrapper'
import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { ViewMode } from '@bbx/search-journey/common/lib/viewModeHelper'
import { joinTeaserAttributes } from '@bbx/search-journey/common/lib/joinTeaserAttributes'

const InternalListOrGridViewAdRow: FunctionComponent<{
    advertSummary: AdvertSummary
    currentSearchResult: SearchResult
    viewMode: ViewMode
    isLoading: boolean
    index: number
    payliveryBadgeText?: string
}> = ({ advertSummary, index, viewMode, isLoading, currentSearchResult, payliveryBadgeText = 'PayLivery' }) => {
    const teaserAttributes = joinTeaserAttributes(advertSummary.teaserAttributes)

    return viewMode === 'list' ? (
        <BapAdRowWrapper
            advertSummary={advertSummary}
            isLoading={isLoading}
            index={index}
            currentSearchResult={currentSearchResult}
            payliveryBadgeText={payliveryBadgeText}
            teaserAttributes={teaserAttributes}
        />
    ) : (
        <GridViewAdRowWrapper
            advertSummary={advertSummary}
            isLoading={isLoading}
            index={index}
            currentSearchResult={currentSearchResult}
            payliveryBadgeText={payliveryBadgeText}
            teaserAttributes={teaserAttributes}
        />
    )
}

export const ListOrGridViewAdRow = React.memo(InternalListOrGridViewAdRow)
