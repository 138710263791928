import { PossibleNavigatorValue } from '@bbx/search-journey/common/Navigators'
import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import React, { Fragment, FunctionComponent } from 'react'
import { BubbleNavigatorButton } from './BubbleNavigatorButton'
import { SearchAttributesMultiSelectModal } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/SearchAttributesMultiSelectModal'
import { useModalSearchAttributes } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/useModalSearchAttributes'

interface BubbleSearchAttributesMultiSelectProps extends NavigatorProps {
    checkedContent?: (possibleValue: PossibleNavigatorValue) => React.ReactNode
    uncheckedContent?: (possibleValue: PossibleNavigatorValue) => React.ReactNode
}

export const BubbleSearchAttributesMultiSelect: FunctionComponent<BubbleSearchAttributesMultiSelectProps> = ({
    navigator,
    onSearch,
    checkedContent,
    uncheckedContent,
    taggingData,
}) => {
    const { checkboxState, handleChange, onSubmitMultiSelect, modalOpen, openModal, closeModal, setCheckboxState } =
        useModalSearchAttributes(navigator, onSearch, taggingData)

    const hasSelectedValues = navigator.selectedValues.length > 0

    return (
        <Fragment>
            <SearchAttributesMultiSelectModal
                navigator={navigator}
                checkboxState={checkboxState}
                onSubmitMultiSelect={onSubmitMultiSelect}
                handleChange={handleChange}
                modalOpen={modalOpen}
                openModal={openModal}
                closeModal={closeModal}
                onSearch={onSearch}
                checkedContent={checkedContent}
                uncheckedContent={uncheckedContent}
                setCheckboxState={setCheckboxState}
                taggingData={taggingData}
            />
            <BubbleNavigatorButton
                id={navigator.id}
                label={navigator.label}
                hasSelectedValues={hasSelectedValues}
                openModal={openModal}
                modalOpen={modalOpen}
                taggingData={taggingData}
            />
        </Fragment>
    )
}
