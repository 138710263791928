import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { getBapAdvertPrice } from '@bbx/search-journey/common/lib/getBapAdvertPrice'
import { getSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import { AdInMotionGallery } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/AdInMotionGallery'
import { ResultListSaveAdButton } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListSaveAdButton'
import { adFocusHoverStyles } from '@wh/common/chapter/components/adFocusHoverStyles'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'
import { AdRowAddressLine } from './AdRowAddressLine'
import { AdRowTimeDelta } from '@bbx/search-journey/common/components/AdvertSummary/AdRowTimeDelta'

interface BapAdInMotionProps {
    advertSummary: AdvertSummary
    taggingData: TaggingData
    teaserAttributes: string | undefined
    className?: string
}

export const BapAdInMotion: FunctionComponent<BapAdInMotionProps> = ({ advertSummary, taggingData, teaserAttributes, className }) => {
    const link = getSeoUrl(advertSummary)
    const highlighted = !!getAttributeValue(advertSummary.attributes.attribute, 'RESULT_LIST_STYLE2')
    const { mainPrice } = getBapAdvertPrice(advertSummary.adTypeId, advertSummary.advertStatus, advertSummary.attributes)
    const oldPrice = getAttributeValue(advertSummary.attributes.attribute, 'OLD_PRICE_FOR_DISPLAY')

    return (
        <Box
            id={advertSummary.id}
            padding="m"
            borderBottom="owl"
            className={className}
            position="relative"
            css={adFocusHoverStyles(highlighted)}
            testId={`adInMotion-${advertSummary.id}`}
        >
            <AdInMotionGallery advertSummary={advertSummary} taggingData={taggingData} />
            <Box position="relative">
                <ClientRoutingAnchorLink
                    type="anchor"
                    href={link}
                    color="palette.verydarkgrey"
                    underline="none"
                    display="flex"
                    flexDirection="column"
                    flex={1}
                    minWidth={0}
                    marginTop="s"
                    testId={`search-result-entry-header-${advertSummary.id}`}
                    css={css`
                        outline: none;
                    `}
                    onClick={() => {
                        return callActionEvent('search_result_list_adinmotion_click', taggingData, { ad_id: `${advertSummary.id}` })
                    }}
                >
                    <Box display="flex" gap="xs" flexDirection="column">
                        <Box display="flex" marginBottom="xs">
                            <Text as="h3" marginBottom="xs" fontSize="l" fontWeight="normal" truncate={true}>
                                {advertSummary.description}
                            </Text>
                            <Box marginLeft="46px" />
                        </Box>
                        <Box display="flex" gap="m">
                            <Box>
                                <Text
                                    fontSize="xl"
                                    fontWeight="bold"
                                    lineHeight={1}
                                    color="palette.primary.main"
                                    flexShrink={0}
                                    testId={`search-result-entry-price-${advertSummary.id}`}
                                >
                                    {mainPrice.value}
                                </Text>
                                {oldPrice && (
                                    <Text
                                        marginLeft="s"
                                        fontSize="m"
                                        color="palette.elephant"
                                        css={css`
                                            text-decoration: line-through;
                                        `}
                                    >
                                        {oldPrice}
                                    </Text>
                                )}
                            </Box>

                            <Text
                                color="palette.elephant"
                                fontSize="m"
                                testId={`search-result-entry-teaser-attributes-${advertSummary.id}`}
                                lineClamp={1}
                            >
                                {teaserAttributes}
                            </Text>
                        </Box>
                        <Text fontSize="s" color="palette.raccoon" lineHeight="1.3" lineClamp={2}>
                            {getAttributeValue(advertSummary.attributes.attribute, 'BODY_DYN')}
                        </Text>
                        <AdRowTimeDelta advertSummary={advertSummary} />
                        <AdRowAddressLine id={advertSummary.id} attributes={advertSummary.attributes} />
                    </Box>
                </ClientRoutingAnchorLink>
                <ResultListSaveAdButton
                    adId={advertSummary.id}
                    adTitle={advertSummary.description}
                    taggingData={taggingData}
                    marginLeft="m"
                    testId={`search-result-entry-save-ad-${advertSummary.id}`}
                    position="absolute"
                    right={0}
                    top={0}
                    zIndex="popover"
                />
            </Box>
        </Box>
    )
}
