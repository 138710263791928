import React, { Fragment, FunctionComponent } from 'react'
import { RecommendationWidgetType } from '@bbx/common/types/UserFeed'
import { Divider } from '@wh-components/core/Divider/Divider'
import { RecommendationsWidgetSlider } from '@bbx/search-journey/common/components/Widgets/RecommendationsWidgetSlider'
import { TaggingWidgetType, useWidgetTagging } from '@bbx/search-journey/common/hooks/useWidgetTagging'
import { callActionEvent, callSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { useLoadRecommendationsWidget } from '@bbx/search-journey/common/lib/useLoadRecommendationsWidget'
import { useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'

interface ResultListRecommendationWidgetOuterWrapperProps {
    visibilityRef: React.RefObject<HTMLDivElement | HTMLAnchorElement>
    taggingData: TaggingData
}

export const ResultListRecommendationWidgetWrapper: FunctionComponent<ResultListRecommendationWidgetOuterWrapperProps> = ({
    visibilityRef,
    taggingData,
}) => {
    const recommendationsWidget = useLoadRecommendationsWidget(visibilityRef)

    if (recommendationsWidget && recommendationsWidget.ads?.length > 0) {
        return <ResultListRecommendationWidget recommendationWidget={recommendationsWidget} taggingData={taggingData} />
    }

    return null
}

interface ResultListRecommendationWidgetWrapperProps {
    recommendationWidget: RecommendationWidgetType
    taggingData: TaggingData
}

export const ResultListRecommendationWidget: FunctionComponent<ResultListRecommendationWidgetWrapperProps> = ({
    recommendationWidget,
    taggingData,
}) => {
    const numberOfAdsLimit = 9

    const { visibilityTrackingRef } = useWidgetTagging({
        ...widgetAndPage,
        adIds: recommendationWidget.ads.map((ad) => ad.id).slice(0, numberOfAdsLimit),
        pulseMetadata: recommendationWidget.pulseMetadata,
    })
    const trackEvent = useOptimizelyTrack(undefined, { page: 'resultlist' })

    return (
        <Fragment>
            <Divider marginBottom="m" />
            <RecommendationsWidgetSlider
                widget={recommendationWidget}
                paddingLeft={{ phone: 'm', tablet: 0 }}
                marginBottom="m"
                onClickAdTagging={(ad, index) => {
                    trackEvent('recommendationsInteraction')
                    trackEvent('recommendationsAdClick')
                    callActionEvent('recommendation_widget_ad_click', taggingData, {
                        ...widgetAndPage,
                        adId: ad.id,
                        adIndex: index + 1,
                        listName: recommendationWidget.pulseMetadata?.listName,
                        transactionId: recommendationWidget.pulseMetadata?.transactionId,
                    })
                    callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
                }}
                onClickShowAllTagging={() => {
                    trackEvent('recommendationsInteraction')
                    trackEvent('recommendationsShowMoreClick')
                    callActionEvent('recommendation_widget_show_all_click', taggingData, {
                        ...widgetAndPage,
                        listName: recommendationWidget.pulseMetadata?.listName,
                        transactionId: recommendationWidget.pulseMetadata?.transactionId,
                        widgetInteractionId: location.href,
                        forcePulse: true,
                    })
                    callActionEvent('ad_widget_show_all_click', taggingData, widgetAndPage)
                    callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
                }}
                onClickShowAllButtonTagging={() => {
                    trackEvent('recommendationsInteraction')
                    trackEvent('recommendationsShowMoreClick')
                    callActionEvent('recommendation_widget_show_all_button_click', taggingData, {
                        ...widgetAndPage,
                        listName: recommendationWidget.pulseMetadata?.listName,
                        transactionId: recommendationWidget.pulseMetadata?.transactionId,
                        widgetInteractionId: location.href,
                        forcePulse: true,
                    })
                    callActionEvent('ad_widget_show_all_button_click', taggingData, widgetAndPage)
                    callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
                }}
                onClickArrowTagging={() => {
                    callActionEvent('recommendation_widget_arrow_click', taggingData, {
                        ...widgetAndPage,
                        listName: recommendationWidget.pulseMetadata?.listName,
                        transactionId: recommendationWidget.pulseMetadata?.transactionId,
                        widgetInteractionId: location.href,
                        forcePulse: true,
                    })
                }}
                visibilityTrackingRef={visibilityTrackingRef}
                numberOfAdsLimit={numberOfAdsLimit}
            />
            <Divider display={{ phone: 'block', tablet: 'none' }} />
        </Fragment>
    )
}

const widgetAndPage = {
    taggingWidgetType: 'RecommendationWidget' as TaggingWidgetType,
    page: 'ResultList',
    source: 'personal-space',
}
