import React, { Fragment, FunctionComponent, useMemo } from 'react'
import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import { Navigator, RangeNavigator } from '@bbx/search-journey/common/Navigators'
import { mergeValues } from '@bbx/search-journey/sub-domains/search/lib/geo-navigators'
import { ProvinceNavigatorModalContent } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/ProvinceNavigator/ProvinceNavigatorModalContent'
import { useProvinceNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/ProvinceNavigator/useProvinceNavigator'
import { MultiSelectModalFooter } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/MultiSelectModalFooter'
import { BubbleNavigatorButton } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/BubbleNavigatorButton'
import { Modal } from '@wh-components/core/Modal/Modal'
import { verticals } from '@wh/common/chapter/types/verticals'
import { getFlatPossibleValues } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'

export interface ProvinceBubbleNavigatorProps {
    districtNavigator: Navigator | RangeNavigator | undefined
}

export const ProvinceBubbleNavigator: FunctionComponent<NavigatorProps & ProvinceBubbleNavigatorProps> = ({
    navigator,
    districtNavigator,
    onSearch,
    taggingData,
}) => {
    const copiedNavigator = useMemo(
        () => ({
            ...navigator,
            selectedValues: mergeValues(navigator.selectedValues, districtNavigator?.selectedValues),
            groupedPossibleValues: [
                {
                    possibleValues: mergeValues(
                        getFlatPossibleValues(navigator),
                        districtNavigator?.groupedPossibleValues[0]?.possibleValues,
                    ),
                },
            ],
        }),
        [navigator, districtNavigator?.selectedValues, districtNavigator?.groupedPossibleValues],
    )

    const { checkboxState, handleChange, modalOpen, openModal, closeModal, onSubmitMultiSelect, setCheckboxState } = useProvinceNavigator(
        copiedNavigator,
        onSearch,
    )

    const hasSelectedValues = copiedNavigator.selectedValues.length > 0

    return (
        <Fragment>
            <Modal
                isOpen={modalOpen}
                onRequestClose={closeModal}
                fullScreen={{ phone: true, tablet: false }}
                width={{ phone: 'auto', tablet: '720px' }}
                height="100%"
                header={navigator.label}
                testId={`${navigator.id}-modal`}
                footer={<MultiSelectModalFooter id={navigator.id} close={closeModal} submit={onSubmitMultiSelect} />}
                footerSeparator={true}
                scrollShadow={true}
            >
                <ProvinceNavigatorModalContent
                    navigator={copiedNavigator}
                    handleChange={handleChange}
                    checkboxState={checkboxState}
                    setCheckboxState={setCheckboxState}
                    onSubmitMultiSelect={onSubmitMultiSelect}
                    vertical={verticals.BAP}
                />
            </Modal>
            <BubbleNavigatorButton
                id={navigator.id}
                label="Bundesland/Bezirk"
                hasSelectedValues={hasSelectedValues}
                openModal={openModal}
                modalOpen={modalOpen}
                taggingData={taggingData}
            />
        </Fragment>
    )
}
