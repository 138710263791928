import { AttributesItem, getAttributeValue } from '@wh/common/chapter/types/Attributes'

export const getRealEstateFooterText = (attributes: AttributesItem[]) => {
    const postcode = getAttributeValue(attributes, 'POSTCODE')
    const location = getAttributeValue(attributes, 'LOCATION')
    const country = getAttributeValue(attributes, 'COUNTRY')

    if (country !== 'Österreich') {
        return country
    }

    return [postcode, location].filter((value) => !!value).join(' ')
}
