import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { getNavigatorById, getValueId } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { buildParentList } from '@bbx/search-journey/sub-domains/search/lib/buildParentList'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'
import { SearchId } from '@bbx/common/api/dtos/search-id'

export const HAUS_GARTEN_WERKSTATT_CATEGORY = '3541'
export const WOHNEN_HAUSHALT_GASTRO_CATEGORY = '5387'
export const KFZ_ZUBEHOER_MOTORRADTEILE_CATEGORY = '6142'

// damen & herren mode for experiment
export const FASHION_CATEGORIES = ['3276', '3393']

export const isBapSearchInCategory = (searchResult: SearchResult, categories: string[], onlyCheckCurrentCategory: boolean = false) => {
    if (searchResult.verticalId !== verticalIdMap.BAP) {
        return false
    }
    const categoryNavigator = getNavigatorById(searchResult, 'category')
    if (!categoryNavigator) {
        return false
    }
    const parents = buildParentList(categoryNavigator.selectedValues).map((parent) => parent.id)

    const currentCategory = categoryNavigator?.selectedValues?.[0]?.urlParamRepresentationForValue?.find(
        (val) => val.urlParameterName === 'ATTRIBUTE_TREE',
    )?.value

    if (onlyCheckCurrentCategory) {
        if (!currentCategory) {
            return false
        }
        return categories.includes(currentCategory)
    }

    return [...parents, currentCategory].filter((cat) => cat !== undefined).some((cat) => categories.includes(cat))
}

export enum carTypes {
    BUS_MINIBUS = '1',
    CABRIO_ROADSTER = '2',
    MOPEDCAR = '3',
    COMPACTCAR = '4',
    KOMBI_FAMILY_VAN = '5',
    LIMOUSINE = '6',
    OLDTIMER = '7',
    PICKUP = '8',
    SPORTSCAR_COUPE = '9',
    SUV_OFFROAD = '10',
    VAN_MINIVAN = '11',
    KLEINBUS = '12',
}

export const isSearchWithCarType = (searchResult: SearchResult, vehicletypes: carTypes[]) => {
    if (searchResult.verticalId !== verticalIdMap.MOTOR && searchResult.searchId !== SearchId.Cars) {
        return false
    }
    return getNavigatorById(searchResult, 'type')
        ?.selectedValues?.map(getValueId)
        ?.some((label) => (vehicletypes as string[])?.includes(label))
}
