import React, { FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { ResultListPagination } from '@bbx/search-journey/sub-domains/search/components/common/result-list/Pagination/ResultListPagination'
import { Button, IconButton } from '@wh-components/core/Button/Button'
import { performGeoSearch, Sorting } from '@bbx/search-journey/sub-domains/search/components/common/common/Sorting/Sorting'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { css } from 'styled-components'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { BubbleNavigators } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/BubbleNavigators'
import { ResetBubbles } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/ResetBubbles/ResetBubbles'
import NearMe from '@wh-components/icons/NearMe'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { AutocompleteKeywordNavigator } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/AutocompleteKeywordNavigator'
import { getNavigatorById, getNumberOfSelectedNavigators } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { Badge } from '@wh-components/core/Badge/Badge'
import { SaveSizeFakeDoor } from './SaveSizeFakeDoor/SaveSizeFakeDoor'

interface BapResultListActionBarProps {
    totalPages: number
    currentPage: number
    currentSearchResult: SearchResult
    triggerSearch: SearchCallback
    regularNonStickyHeaderVisibilityTrackingRef: React.RefObject<HTMLDivElement>
    setShowNavigatorSidebar: (value: boolean) => void
    taggingData: TaggingData
    isImageSearch: boolean
    payliveryBadgeText?: string
}

export const BapResultListActionBar: FunctionComponent<BapResultListActionBarProps> = ({
    currentSearchResult,
    triggerSearch,
    regularNonStickyHeaderVisibilityTrackingRef,
    totalPages,
    currentPage,
    setShowNavigatorSidebar,
    taggingData,
    isImageSearch,
    payliveryBadgeText = 'PayLivery',
}) => {
    const nearMeSortingLink = findContextLinkWithId('distance.ascending', currentSearchResult.sortOrderList)
    const publishedSortingLink = findContextLinkWithId('published.descending', currentSearchResult.sortOrderList)
    const numberOfSelectedNavigators = getNumberOfSelectedNavigators(currentSearchResult.navigatorGroups)

    const autocompleteNavigator = getNavigatorById(currentSearchResult, 'keyword')

    return (
        <Box
            display="flex"
            flexDirection={{ phone: 'column-reverse', tablet: 'column' }}
            paddingHorizontal={{ phone: 'm', tablet: 0 }}
            backgroundColor={{ phone: 'palette.babyseal', tablet: 'transparent' }}
            ref={regularNonStickyHeaderVisibilityTrackingRef}
        >
            <SaveSizeFakeDoor slot="srlMobile" searchResult={currentSearchResult} marginBottom="m" marginTop="s" />
            <Box
                paddingBottom="s"
                borderBottom={{ tablet: 'owl' }}
                css={css`
                    &:empty {
                        padding: 0;
                    }
                `}
            >
                <Box
                    marginBottom={{ phone: 's', tablet: 'xs' }}
                    css={css`
                        ${(p) => p.theme.media.tablet} {
                            margin-top: -${(p) => p.theme.space.xs}px;
                        }
                    `}
                >
                    <BubbleNavigators searchResult={currentSearchResult} onSearch={triggerSearch} payliveryBadgeText={payliveryBadgeText} />
                </Box>
                <ResetBubbles searchResult={currentSearchResult} onSearch={triggerSearch} isImageSearch={isImageSearch} />
            </Box>
            <Box
                testId="result-list-action-bar"
                display={{ phone: 'flex', tablet: currentSearchResult.rowsFound > 0 ? 'flex' : 'none' }}
                justifyContent="space-between"
                paddingVertical="s"
                borderBottom={{ tablet: 'owl' }}
            >
                <Box display={{ phone: 'none', tablet: 'block' }}>
                    <ResultListPagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        searchResult={currentSearchResult}
                        onSearch={triggerSearch}
                        testId="pagination-top"
                    />
                </Box>
                <Box display={{ tablet: 'none' }} flexGrow="2" marginRight="s">
                    <Button
                        size="small"
                        width="100%"
                        testId="filter-button-small"
                        onClick={() => {
                            setShowNavigatorSidebar(true)
                            return callActionEvent('search_result_list_filter_small_click', taggingData)
                        }}
                    >
                        Filtern
                        {!!numberOfSelectedNavigators && numberOfSelectedNavigators > 0 && (
                            <Badge fontSize="xs" paddingVertical={0} paddingHorizontal="xs" marginLeft="xs" variant="outline">
                                {String(numberOfSelectedNavigators)}
                            </Badge>
                        )}
                    </Button>
                </Box>

                {nearMeSortingLink && (
                    <IconButton
                        display={{ phone: 'inline-flex', tablet: 'none' }}
                        color={nearMeSortingLink.selected ? 'primary' : 'complimentary'}
                        variant="outline"
                        Icon={NearMe}
                        size="small"
                        marginRight="s"
                        onClick={() => {
                            if (!nearMeSortingLink.selected) {
                                callActionEvent('search_result_list_sortorder_nearme_button', taggingData)
                                performGeoSearch(nearMeSortingLink, triggerSearch)
                            } else {
                                triggerSearch(publishedSortingLink!)
                            }
                        }}
                    />
                )}
                <Sorting
                    onSearch={triggerSearch}
                    sortOrderList={currentSearchResult.sortOrderList}
                    taggingData={taggingData}
                    testId="sorting-select"
                />
            </Box>
            {autocompleteNavigator && (
                <Box display={{ tablet: 'none' }} paddingTop="s" zIndex="overlay">
                    <AutocompleteKeywordNavigator
                        navigator={autocompleteNavigator}
                        autocompleteContextLink={findContextLinkWithId('autocomplete', currentSearchResult.autocompleteLinkList)}
                        onSearch={triggerSearch}
                        taggingData={taggingData}
                        showLabel={false}
                        size="small"
                        testId="suchbegriff-small"
                        autocompleteTaggingEvent="search_result_list_keyword_search_autocomplete"
                        lastSearchTaggingEvent="search_result_list_keyword_search_last"
                        searchTaggingEvent="search_result_list_keyword_search_search"
                    />
                </Box>
            )}
        </Box>
    )
}
