import React, { FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { Button } from '@wh-components/core/Button/Button'
import { Divider } from '@wh-components/core/Divider/Divider'
import { BapAutocompleteCategory } from '@bbx/search-journey/common/BapAutocompleteResponse'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { getNavigatorById } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { SortOrderInfo } from '@bbx/search-journey/sub-domains/search/components/common/result-list/SortOrderInfo/SortOrderInfo'

interface CategorySuggestionsBubbleProps {
    suggestedCategories?: BapAutocompleteCategory[] | null
    triggerSearch: SearchCallback
    searchResult: SearchResult
}

export const CategorySuggestionBubbles: FunctionComponent<CategorySuggestionsBubbleProps> = ({
    suggestedCategories,
    triggerSearch,
    searchResult,
}) => {
    if (!showCategorySuggestionBubbles(searchResult, suggestedCategories)) {
        return null
    }

    const baseLink = findContextLinkWithId('searchBaseLink', searchResult.searchContextLinks)

    return (
        <Box display="flex" flexDirection={{ phone: 'column', tablet: 'row' }}>
            <Box display="flex" flexDirection="column" padding="s" paddingLeft={{ phone: 'm', tablet: 's' }} flexGrow={1}>
                <Box marginBottom="xs">
                    <Text fontSize="s" color="palette.elephant" testId="category-suggestion-text">
                        Zu viele Treffer? Wähle eine Kategorie
                    </Text>
                </Box>
                <Box display="flex" flexWrap="wrap" testId="category-suggestion-buttons">
                    {suggestedCategories?.map((category) => (
                        <Button
                            key={category.id}
                            marginRight="xs"
                            marginBottom="xs"
                            maxWidth="288px"
                            size="xsmall"
                            color="complimentary"
                            variant="outline"
                            onClick={() => {
                                callActionEvent('search_result_list_category_suggestion', searchResult.taggingData)
                                triggerSearch(baseLink!, { ATTRIBUTE_TREE: String(category.id) })
                            }}
                            testId={`category-suggestion-button-${category.id}`}
                        >
                            <Text truncate={true} fontSize="s" color="palette.raccoon" fontWeight="regular">
                                {category?.name}
                            </Text>
                        </Button>
                    ))}
                </Box>
            </Box>
            <Divider marginVertical="xs" display={{ phone: 'block', tablet: 'none' }} />
            <SortOrderInfo />
        </Box>
    )
}

export const showCategorySuggestionBubbles = (searchResult: SearchResult, suggestedCategories?: BapAutocompleteCategory[] | null) => {
    return !(
        !suggestedCategories ||
        suggestedCategories.length === 0 ||
        !findContextLinkWithId('searchBaseLink', searchResult.searchContextLinks) ||
        searchResult.rowsFound < 15 ||
        isCategorySelected(searchResult) ||
        isTreeAttributeSelected(searchResult)
    )
}

const isCategorySelected = (searchResult: SearchResult) => {
    return getNavigatorById(searchResult, 'category')?.selectedValues.length > 0
}

const isTreeAttributeSelected = (searchResult: SearchResult) => {
    return searchResult.navigatorGroups
        .map((group) => group.navigatorList)
        .reduce((a, b) => a.concat(b), [])
        .filter((navigator) => navigator.urlConstructionInformation.urlParams?.[0]?.urlParameterName === 'treeAttributes')
        .some((navigator) => navigator.selectedValues.length > 0)
}
