import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import React, { FunctionComponent } from 'react'
import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { ResultListAdInMotionSkeleton } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListAdInMotionSkeleton'
import { css } from 'styled-components'
import { scrollMargin } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListAdRows'
import { BapAdInMotion } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/AdRow/BapAdInMotion'
import { ResultListAdRowSkeleton } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListAdRowSkeleton'
import { BapAdRow } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/AdRow/BapAdRow'
import { baseFontSize } from '@wh-components/core/theme/fontSizeHelper'
import { AdRowDynamicRendering } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRowDynamicRendering'

export const BapAdRowWrapper: FunctionComponent<{
    currentSearchResult: SearchResult
    isLoading: boolean
    index: number
    advertSummary: AdvertSummary
    payliveryBadgeText: string
    teaserAttributes: string | undefined
}> = ({ currentSearchResult, isLoading, index, advertSummary, payliveryBadgeText, teaserAttributes }) => {
    const isAdinMotion = getAttributeValue(advertSummary.attributes.attribute, 'UPSELLING_RESULT_LIST_AD_IN_MOTION')

    if (isAdinMotion) {
        return (
            <AdRowDynamicRendering index={index} id={advertSummary.id} nrOfAds={5} height={{ phone: 405, tablet: 458, desktop: 502 }}>
                {isLoading ? (
                    <ResultListAdInMotionSkeleton
                        height={{ phone: 405, tablet: 458, desktop: 502 }}
                        css={css`
                            ${scrollMargin}
                        `}
                    />
                ) : (
                    <BapAdInMotion
                        advertSummary={advertSummary}
                        taggingData={currentSearchResult.taggingData}
                        teaserAttributes={teaserAttributes}
                        css={css`
                            ${scrollMargin}
                        `}
                    />
                )}
            </AdRowDynamicRendering>
        )
    } else {
        return (
            <AdRowDynamicRendering
                index={index}
                id={advertSummary.id}
                nrOfAds={5}
                height={{ phone: 173, tablet: 233, desktop: `${182 / baseFontSize}rem` }}
            >
                {isLoading ? (
                    <ResultListAdRowSkeleton
                        estimatedPhoneTitleLines={2}
                        css={css`
                            ${scrollMargin}
                            content-visibility: auto;
                            contain-intrinsic-size: 165px;

                            ${(p) => p.theme.media.tablet} {
                                contain-intrinsic-size: 183px;
                            }
                        `}
                    />
                ) : (
                    <BapAdRow
                        advertSummary={advertSummary}
                        taggingData={currentSearchResult.taggingData}
                        teaserAttributes={teaserAttributes}
                        payliveryBadgeText={payliveryBadgeText}
                        css={css`
                            ${scrollMargin}
                        `}
                    />
                )}
            </AdRowDynamicRendering>
        )
    }
}
