import React, { Fragment, FunctionComponent, useState } from 'react'
import { Modal } from '@wh-components/core/Modal/Modal'
import { BubbleNavigatorButton } from './BubbleNavigatorButton'
import { RangeNavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/RangeNavigatorComponent/RangeNavigatorComponent'
import { RangeNavigatorModalFooter } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/RangeNavigatorModalFooter'
import { BoxProps } from '@wh-components/core/Box/Box'
import { PriceNavigator } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/PriceNavigator'

export const BubblePriceNavigator: FunctionComponent<RangeNavigatorProps & BoxProps> = ({ navigator, onSearch, taggingData }) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const hasSelectedValues = navigator.selectedValues.length > 0

    const openModal = () => {
        setModalOpen(true)
    }

    const closeModal = () => {
        setModalOpen(false)
    }

    return (
        <Fragment>
            <Modal
                isOpen={modalOpen}
                onRequestClose={closeModal}
                fullScreen={{ phone: true, tablet: false }}
                width={{ phone: 'auto', tablet: '500px' }}
                header={navigator.label}
                testId={`${navigator.id}-modal`}
                footer={<RangeNavigatorModalFooter id={navigator.id} onClick={closeModal} />}
                footerSeparator={true}
                scrollShadow={true}
            >
                <PriceNavigator
                    navigator={navigator}
                    id={`bubble-${navigator.id}`}
                    onSearch={onSearch}
                    taggingData={taggingData}
                    abortRequest={() => {}}
                    marginBottom="s"
                    label=""
                />
            </Modal>

            <BubbleNavigatorButton
                id={navigator.id}
                label={navigator.label}
                hasSelectedValues={hasSelectedValues}
                openModal={openModal}
                modalOpen={modalOpen}
                taggingData={taggingData}
            />
        </Fragment>
    )
}
