import { topAdAddressConfig, TopAdConfig } from '../../../../common/result-list/TopAds/topAdConfig'
import { FormattedAttributesItem } from '@wh/common/chapter/types/Attributes'

const buildStringFromPriceItems = (prices: (FormattedAttributesItem | undefined)[]) => {
    const firstPrice = prices.find(Boolean) ?? prices[0]
    const formattedValues = firstPrice?.formattedValues
    return formattedValues ? formattedValues[0] : ''
}

const topAdBapConfig: TopAdConfig = {
    teaserAttributes: [],
    buildStringFromPriceItems: buildStringFromPriceItems,
    priceAttributes: ['PRICE_FOR_DISPLAY'],
    ...topAdAddressConfig,
}

type BapAdType = 'BapFree' | 'BapWebstoreobject' | 'BapSalesobjectPro'

export const topAdBapConfigMap: Record<BapAdType, TopAdConfig> = {
    BapFree: topAdBapConfig,
    BapWebstoreobject: topAdBapConfig,
    BapSalesobjectPro: topAdBapConfig,
}
