import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import React, { FunctionComponent } from 'react'
import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { css } from 'styled-components'
import { scrollMargin } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListAdRows'
import { BapGridViewAdRow } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/AdRow/BapGridViewAdRow'
import { baseFontSize } from '@wh-components/core/theme/fontSizeHelper'
import { AdRowDynamicRendering } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRowDynamicRendering'
import { ResultListGridSkeleton } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListGridSkeleton'

export const GridViewAdRowWrapper: FunctionComponent<{
    currentSearchResult: SearchResult
    isLoading: boolean
    index: number
    advertSummary: AdvertSummary
    payliveryBadgeText: string
    teaserAttributes: string | undefined
}> = ({ currentSearchResult, isLoading, index, advertSummary, payliveryBadgeText, teaserAttributes }) => {
    return (
        <AdRowDynamicRendering
            index={index}
            id={advertSummary.id}
            className="grid-view-ad-cell"
            nrOfAds={6}
            height={{ phone: `${257 / baseFontSize}rem` }}
        >
            {isLoading ? (
                <ResultListGridSkeleton
                    css={css`
                        ${scrollMargin}
                        content-visibility: auto;
                        contain-intrinsic-size: 250px;

                        ${(p) => p.theme.media.tablet} {
                            contain-intrinsic-size: 360px;
                        }
                    `}
                />
            ) : (
                <BapGridViewAdRow
                    advertSummary={advertSummary}
                    taggingData={currentSearchResult.taggingData}
                    payliveryBadgeText={payliveryBadgeText}
                    teaserAttributes={teaserAttributes}
                    css={css`
                        ${scrollMargin}
                    `}
                />
            )}
        </AdRowDynamicRendering>
    )
}
