import React, { FunctionComponent } from 'react'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { NavigatorGroup, RangeNavigator, Navigator } from '@bbx/search-journey/common/Navigators'
import { BubbleNavigatorDiscriminator } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/BubbleNavigatorDiscriminator'
import { Box } from '@wh-components/core/Box/Box'
import { PayliveryToggleNavigator } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/PayliveryToggleNavigator'
import { theme } from '@wh-components/core/theme'
import { css } from 'styled-components'
import { hiddenScrollbar } from '@wh/common/chapter/components/hiddenScrollbar'
import { getNavigatorById } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'

interface BubbleNavigatorsProps {
    searchResult: SearchResult
    onSearch: SearchCallback
    payliveryBadgeText: string
}

export const BubbleNavigators: FunctionComponent<BubbleNavigatorsProps> = ({ onSearch, searchResult, payliveryBadgeText }) => {
    return (
        <Box
            paddingLeft={{ phone: 'm', tablet: '0px' }}
            display="flex"
            alignItems="center"
            flexWrap={{ tablet: 'wrap' }}
            width={{ phone: `calc(100% + ${theme.space.xl}px)`, tablet: 'auto' }}
            overflowX={{ phone: 'scroll', tablet: 'visible' }}
            paddingRight={{ phone: 'm', tablet: 0 }}
            css={css`
                margin-left: -${(p) => p.theme.space.m}px;

                ${hiddenScrollbar}

                ${(p) => p.theme.media.tablet} {
                    margin-left: 0;
                }

                & > * {
                    margin-right: ${(p) => p.theme.space.xs}px;

                    ${(p) => p.theme.media.tablet} {
                        margin-right: ${(p) => p.theme.space.s}px;
                    }
                }
            `}
        >
            {getBubbleNavigators(searchResult.navigatorGroups).map((navigator) => {
                return navigator.id === 'paylivery' ? (
                    <PayliveryToggleNavigator
                        key={navigator.id}
                        navigator={navigator}
                        onSearch={onSearch}
                        taggingData={searchResult.taggingData}
                        order={{ phone: '-1', tablet: '10000' }}
                        payliveryBadgeText={payliveryBadgeText}
                    />
                ) : (
                    <BubbleNavigatorDiscriminator
                        key={navigator.id}
                        navigator={navigator}
                        districtNavigator={getNavigatorById(searchResult, 'district')}
                        onSearch={onSearch}
                        taggingData={searchResult.taggingData}
                    />
                )
            })}
        </Box>
    )
}

const getBubbleNavigators = (navigatorGroups: NavigatorGroup[]): (Navigator | RangeNavigator)[] => {
    return navigatorGroups
        .map((group) => group.navigatorList)
        .reduce((a, b) => a.concat(b), [])
        .filter((navigator) => navigator.navigatorValuesDisplayStyle?.includes('BUBBLE'))
    // .sort((a) => (a.id === 'paylivery' ? -1 : 1))
}
