import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'
import dayjs from 'dayjs'
import React, { FunctionComponent } from 'react'
import { Text } from '@wh-components/core/Text/Text'
import { SavedAdsItem } from '@bbx/common/types/savedAds'

interface AdRowTimeDeltaProps {
    advertSummary: AdvertSummary | SavedAdsItem
    color?: string
}

export const AdRowTimeDelta: FunctionComponent<AdRowTimeDeltaProps> = ({ advertSummary, color }) => {
    const timeDelta = getTimeDelta(getAttributeValue(advertSummary.attributes.attribute, 'PUBLISHED'))

    return timeDelta ? (
        <Text
            fontSize="s"
            color={color ?? (timeDelta.recent ? 'palette.primary.main' : 'palette.raccoon')}
            as="p"
            aria-label={`veröffentlicht ${timeDelta.text}`}
        >
            {timeDelta.text}
        </Text>
    ) : null
}

const getTimeDelta = (publishedDateAsUnixString: string | undefined): { text: string; recent: boolean } | undefined => {
    if (!publishedDateAsUnixString) {
        return undefined
    }
    const publishedDate = dayjs(Number(publishedDateAsUnixString))
    const timestamp = dayjs(new Date())
    const deltaInMin = timestamp.diff(publishedDate, 'minute')
    if (publishedDate.format('YYYY-MM-DD') === timestamp.format('YYYY-MM-DD')) {
        if (deltaInMin <= 60) {
            return { text: `Heute, ${publishedDate.format('HH:mm')} Uhr`, recent: true }
        }
        return { text: `Heute, ${publishedDate.format('HH:mm')} Uhr`, recent: false }
    } else {
        return { text: `${publishedDate.format('DD.MM. - HH:mm')} Uhr`, recent: false }
    }
}
