import React, { FunctionComponent } from 'react'
import { SeoSearchTerms } from '@bbx/search-journey/common/SeoSearchTerms'
import { LinkVomit } from '@bbx/search-journey/common/components/LinkVomit/LinkVomit'
import { ResponsiveValue } from '@wh-components/system'

export const SearchTermsLinkVomit: FunctionComponent<{
    searchTermsList: SeoSearchTerms[]
    alignLinks?: ResponsiveValue<'row' | 'column'>
}> = ({ searchTermsList, alignLinks }) => {
    const seoSearchTermsLinkGroup = searchTermsList.map((searchTerms) => ({
        title: searchTerms.title,
        links: searchTerms.searchTerms.map((searchTerm) => ({ name: searchTerm.name, link: searchTerm.url })),
    }))

    return <LinkVomit linkGroups={seoSearchTermsLinkGroup} testId="search-term-vomit" alignLinks={alignLinks} />
}
