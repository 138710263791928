import { RecommendationWidgetType } from '@bbx/common/types/UserFeed'
import { getRecommendations } from '@bbx/search-journey/common/api/cdcApiClient'
import { isUserLoggedIn, useProfileData } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { useIsInView } from '@wh/common/chapter/hooks/useIsInView'
import React, { useEffect, useState } from 'react'

export const useLoadRecommendationsWidget = (visibilityRef: React.RefObject<HTMLDivElement | HTMLAnchorElement>) => {
    const [recommendationsWidget, setRecommendationsWidget] = useState<RecommendationWidgetType | undefined>(undefined)
    const [isTargetInView] = useIsInView(visibilityRef, '500px')
    const [profileData] = useProfileData()

    useEffect(() => {
        const loadRecommendationsWidget = async () => {
            try {
                if (isUserLoggedIn(profileData)) {
                    const recommendations = await getRecommendations(profileData.uuid)
                    setRecommendationsWidget(recommendations)
                }
            } catch (_error) {}
        }

        if (isTargetInView) {
            loadRecommendationsWidget()
        }
    }, [isTargetInView, profileData])

    return recommendationsWidget
}
