import React, { Fragment, FunctionComponent } from 'react'
import { IconButton } from '@wh-components/core/Button/Button'
import Gridview from '@wh-components/icons/Gridview'
import Listview from '@wh-components/icons/Listview'
import { ViewMode } from '@bbx/search-journey/common/lib/viewModeHelper'

interface ViewModeButtonsProps {
    viewMode: ViewMode
    activateListView: () => void
    activateGridView: () => void
}

export const ViewModeButtons: FunctionComponent<ViewModeButtonsProps> = ({ activateGridView, activateListView, viewMode }) => {
    return (
        <Fragment>
            <IconButton
                name="ListView"
                Icon={Listview}
                size="small"
                variant="transparent"
                color={viewMode === 'list' ? 'primary' : 'complimentary'}
                onClick={activateListView}
                marginLeft="m"
                aria-label={viewMode === 'list' ? 'Listenansicht bereits aktiv' : 'Listenansicht wählen'}
            />
            <IconButton
                name="GridView"
                Icon={Gridview}
                size="small"
                variant="transparent"
                color={viewMode === 'grid' ? 'primary' : 'complimentary'}
                onClick={activateGridView}
                aria-label={viewMode === 'grid' ? 'Tabellenansicht bereits aktiv' : 'Tabellenansicht wählen'}
            />
        </Fragment>
    )
}
