import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import { Box } from '@wh-components/core/Box/Box'
import { Toggle } from '@wh-components/core/FormElements/Toggle/Toggle'
import React, { FunctionComponent } from 'react'
import { FlexboxProps } from '@wh-components/system/flexbox'
import { usePayliveryNavigator } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/usePayliveryNavigator'
import { MarginProps } from '@wh-components/system/space'
import { PayliveryBadge } from '@wh/common/chapter/components/PayliveryBadge/PayliveryBadge'
import { Text } from '@wh-components/core/Text/Text'

interface PayliveryExperimentLabelProps {
    payliveryBadgeText: string
}

export const PayliveryToggleNavigator: FunctionComponent<NavigatorProps & FlexboxProps & PayliveryExperimentLabelProps> = ({
    navigator,
    onSearch,
    taggingData,
    payliveryBadgeText,
    ...props
}) => {
    const { toggleState, toggleToggle } = usePayliveryNavigator(navigator, onSearch, taggingData, 'search_result_list_paylivery_toggle')

    return (
        <Box display="flex" marginLeft="xs" marginBottom={0} height={{ phone: '100%', tablet: '40px' }} {...props}>
            <Toggle
                alignSelf="center"
                id="paylivery-toggle"
                testId="paylivery-toggle"
                marginRight="xs"
                onChange={toggleToggle}
                checked={toggleState}
                label={<PayliveryNavigatorLabel payliveryBadgeText={payliveryBadgeText} />}
            />
        </Box>
    )
}

const PayliveryNavigatorLabel: FunctionComponent<MarginProps & PayliveryExperimentLabelProps> = ({ payliveryBadgeText }) => {
    return (
        <Box display="flex" justifyContent="center" flexDirection="column" marginBottom={0} marginLeft="s">
            <Box>
                <PayliveryBadge id="paylivery-navigator" text={payliveryBadgeText} />
            </Box>
            <Text color="palette.raccoon" fontSize="xs" display={{ desktop: 'block', phone: 'none' }}>
                Anzeigen mit Käuferschutz und Versand
            </Text>
        </Box>
    )
}
