import React, { FunctionComponent, useContext } from 'react'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'
import { CdcBapAdvertSummaryComponent } from '@bbx/search-journey/common/components/AdvertSummary/CdcBapAdvertSummaryComponent'
import { CdcRealEstateAdvertSummaryComponent } from '@bbx/search-journey/common/components/AdvertSummary/CdcRealEstateAdvertSummaryComponent'
import { CdcAutoMotorAdvertSummaryComponent } from '@bbx/search-journey/common/components/AdvertSummary/CdcAutoMotorAdvertSummaryComponent'
import { CdcJobAdvertSummaryComponent } from '@bbx/search-journey/common/components/AdvertSummary/CdcJobAdvertSummaryComponent'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { CdcAdvertSummaryProps } from '@bbx/search-journey/common/components/AdvertSummary/CdcAdvertSummaryProps'

export const CdcAdvertSummaryDiscriminator: FunctionComponent<CdcAdvertSummaryProps> = ({
    ad,
    testIdPrefix,
    isActive = true,
    onClickTaggingEvent,
    variant = 'portrait',
    jobsVariant,
    imgHeight,
}) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const color = advertisingState.pageModifications.foregroundColors?.['startpage-standard-color']
    const accentColor = advertisingState.pageModifications.foregroundColors?.['startpage-accent-color']

    const commonProps = {
        ad: ad,
        testIdPrefix: testIdPrefix,
        isActive: isActive,
        onClickTaggingEvent: onClickTaggingEvent,
        color: color,
        accentColor: accentColor,
        imgHeight: imgHeight,
    }

    if (ad.verticalId === verticalIdMap.BAP) {
        return <CdcBapAdvertSummaryComponent {...commonProps} variant={variant} />
    } else if (ad.verticalId === verticalIdMap.ESTATE) {
        return <CdcRealEstateAdvertSummaryComponent {...commonProps} />
    } else if (ad.verticalId === verticalIdMap.MOTOR) {
        return <CdcAutoMotorAdvertSummaryComponent {...commonProps} />
    } else if (ad.verticalId === verticalIdMap.JOB) {
        return <CdcJobAdvertSummaryComponent {...commonProps} jobsVariant={jobsVariant} />
    }
    return null as unknown as JSX.Element
}
