import { AdvertStatus } from '@bbx/common/types/ad-detail/AdvertDetails'
import { AttributesList, getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { adTypeIdMap } from '@wh/common/chapter/types/AdType'
import { adStatusFlag } from '@wh/common/chapter/lib/advertStatusColorHelper'
import { getPriceByStatus } from '@bbx/search-journey/common/lib/getAdvertPrice'
import { Price } from '@bbx/common/types/Price'

export const getBapAdvertPrice = (adType: number, adStatus: AdvertStatus | null, attributes: AttributesList) => {
    const price = getAttributeValue(attributes.attribute, 'PRICE_FOR_DISPLAY') || 'Keine Angabe'
    const isForFree = getAttributeValue(attributes.attribute, 'PRICE') === '0' || false

    switch (adType) {
        case adTypeIdMap.BAP_WEBSTORE_OBJECT: // dealer (shops)
            return {
                mainPrice: getPriceByStatus({ label: 'Verkaufspreis', value: price }, adStatus),
            }
        case adTypeIdMap.BAP_FREE: // private
        case adTypeIdMap.BAP_SALESOBJECT_PRO: // commercial
            return { mainPrice: getBapPrice(price, adStatus, isForFree) }
        default:
            return { mainPrice: { label: 'Preis', value: price } }
    }
}

const getBapPrice = (mainPrice: string, advertStatus: AdvertStatus | null, isForFree: boolean): Price => {
    if (advertStatus && advertStatus.id === adStatusFlag.SOLD) {
        return isForFree ? { label: '', value: 'verschenkt' } : { label: 'VERKAUFT', value: mainPrice }
    } else {
        return { label: isForFree ? '' : 'Verkaufspreis', value: mainPrice }
    }
}
