import React, { FunctionComponent } from 'react'
import { verticals } from '@wh/common/chapter/types/verticals'
import { AdvertSummaryComponent } from '@bbx/search-journey/common/components/AdvertSummary/AdvertSummary'
import { getSeoUrlCdc } from '@bbx/search-journey/common/lib/getSeoUrl'
import { CdcAdvertSummaryProps } from '@bbx/search-journey/common/components/AdvertSummary/CdcAdvertSummaryProps'
import { getRealEstateFooterText } from '@bbx/search-journey/common/components/AdvertSummary/getFooterText'

export const CdcRealEstateAdvertSummaryComponent: FunctionComponent<CdcAdvertSummaryProps> = ({
    ad,
    testIdPrefix,
    isActive = true,
    onClickTaggingEvent,
    color,
    accentColor,
}) => {
    const footerText = getRealEstateFooterText(ad.attributes)

    return (
        <AdvertSummaryComponent
            attributes={ad.attributes}
            key={ad.id}
            adTypeId={ad.adTypeId}
            description={ad.description}
            advertImage={ad.advertImageList?.[0]?.mainImageUrl}
            advertStatus={ad.advertStatus}
            href={getSeoUrlCdc(ad)}
            teaserAttributes={ad.teaserAttributes}
            footerText={footerText}
            vertical={verticals.ESTATE}
            adId={String(ad.id)}
            testIdPrefix={testIdPrefix}
            isActive={isActive}
            onClickTaggingEvent={onClickTaggingEvent}
            color={color}
            inactiveColor={color}
            accentColor={accentColor}
        />
    )
}
